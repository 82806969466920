import React from 'react';
import { Select, Button } from 'tg-core-components';
import cn from 'classnames';
import { getContinueLabel } from '../../helpers';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { format as moneyFormat } from 'tg-core-components/lib/components/Money';
import { validateRules, required } from '../../validation';
import { useSelector } from 'react-redux';

import './style.css';

const AnnualActivity = ({
  errors,
  onChange,
  fields,
  nextStep,
  statuses,
  intl,
  device,
}) => {
  const cmsConfig = useSelector(state => state.content.config.data);

  const isValid =
    statuses.ExpectedAnnualActivityFrom === 'success' &&
    statuses.ExpectedAnnualActivityTo === 'success';
  const currency = fields.Currency || 'EUR';

  const activityOptions = cmsConfig?.find(c => c.key === 'annual-activity')
    ?.value;

  const formattedValues = activityOptions?.[currency].map(v => ({
    max: v.max ? moneyFormat(currency).format(v.max) : '',
    min: moneyFormat(currency).format(v.min),
  }));

  const value = fields.ActivityOptions?.[currency].find(a =>
    a.min.includes(fields.ExpectedAnnualActivityFrom)
  );

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Select
        name="Activity"
        value={value}
        onChange={(_, value) =>
          onChange({
            ExpectedAnnualActivityFrom: activityOptions?.[currency]?.find(
              a => moneyFormat(currency).format(a.min) == value
            ).min,
            ExpectedAnnualActivityTo: activityOptions?.[currency]?.find(
              a => moneyFormat(currency).format(a.min) == value
            ).max,
          })
        }
        status={statuses.ExpectedAnnualActivityFrom}
        statusText={
          errors.ExpectedAnnualActivityFrom &&
          translate({ id: errors.ExpectedAnnualActivityFrom }, intl)
        }>
        <Select.Option value="" selected disabled>
          {translate(
            {
              id: 'label.expected-annual-activity',
              defaultMessage: 'Expected Annual Activity',
            },
            intl
          )}
        </Select.Option>
        {formattedValues.map((c, i) => (
          <Select.Option
            key={i}
            value={c.min}
            hasFocus={formattedValues[1] === c}>
            {c.min}
            {i === formattedValues.length - 1 || c.max === c.min
              ? ' + '
              : ' - '}
            {c.max && c.max !== c.min ? c.max : ''}
          </Select.Option>
        ))}
      </Select>
      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({
  ExpectedAnnualActivityFrom,
  ExpectedAnnualActivityTo,
}) => {
  const rules = {
    ExpectedAnnualActivityFrom: [
      [required, 'error.empty.expected-annual-activity'],
    ],
    ExpectedAnnualActivityTo: [
      [required, 'error.empty.expected-annual-activity'],
    ],
  };

  const errors = await validateRules(
    {
      ExpectedAnnualActivityFrom,
      ExpectedAnnualActivityTo,
    },
    rules
  );

  return Promise.resolve({ errors });
};

export default {
  name: 'activity',
  Component: AnnualActivity,
  icon: 'question-circle',
  validate,
  fields: ['ExpectedAnnualActivityFrom', 'ExpectedAnnualActivityTo'],
};
