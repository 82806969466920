import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import marked from 'marked';
import {
  Button,
  Input,
  Textarea,
  Radio,
  Translate,
  Select,
} from 'tg-core-components';
import kycActions from 'tg-core-redux/lib/modules/kyc/action';
import Alert, { createAlertId } from '@components/Alert';
import Popup from '@components/Popup';
import withRouteData from '@utils/withRouteData';
import { loadContents } from '@actions/content';
import translate from 'tg-core-components/lib/lib/utils/translate';

import './style.css';

@injectIntl
@withRouteData
@connect(
  state => ({
    sections: state.content.sections.data,
    sets: state.content.sets.data,
    sessionId: state.player.sessionId,
    currency: state.player.activeCurrency,
    jurisdiction: state.app.jurisdiction,
  }),
  {
    sourceOfWealth: kycActions.sourceOfWealthV2,
  }
)
class SourceOfWealthContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'section',
      identifiers: ['source-of-wealth-intro', 'source-of-wealth-completed'],
    },
    {
      name: 'set',
      identifiers: ['source-of-wealth-questions'],
    },
  ]);

  state = {
    step: 0,
    isLoading: false,
    source: { value: [], showOther: false, other: '' },
    wealth: { income: '', incomeFrom: '', incomeTo: '', hasConfirmed: false },
    expectedAnnualActivity: {
      activity: '',
      activityFrom: '',
      activityTo: '',
      type: '',
    },
    occupation: {
      industry: '',
      level: '',
      showOther: false,
      showLevel: true,
      other: '',
    },
    error: false,
    enablePopupClose: false,
  };

  nextStep = () => this.setState({ step: this.state.step + 1 });
  previousStep = () => this.setState({ step: this.state.step - 1 });

  selectSource = e => {
    const { source } = this.state;

    const name = e.target.name;
    const selectedSources = source.value;

    const indexOfSelected = selectedSources.indexOf(name);

    if (indexOfSelected !== -1) {
      selectedSources.splice(indexOfSelected, 1);
    } else if (name !== 'other') {
      selectedSources.push(name);
    }

    this.setState({
      source: {
        ...source,
        value: selectedSources,
        showOther: name === 'other' ? !source.showOther : source.showOther,
      },
    });
  };

  selectIncome = (e, alternatives) => {
    const alternative = alternatives.find(a => a.value === e.target.value);
    this.setState({
      wealth: {
        ...this.state.wealth,
        income: e.target.value,
        incomeFrom: Number(alternative.valueFrom),
        incomeTo: Number(alternative.valueTo),
      },
    });
  };

  selectExpectedAnnualActivity = (e, alternatives) => {
    const alternative = alternatives.find(a => a.value === e.target.value);
    this.setState({
      expectedAnnualActivity: {
        ...this.state.expectedAnnualActivity,
        activity: e.target.value,
        activityFrom: Number(alternative.valueFrom),
        activityTo: Number(alternative.valueTo),
      },
    });
  };

  handleSubmit = () => {
    const { source, wealth, occupation } = this.state;
    const { sessionId, sourceOfWealth } = this.props;

    this.setState({ isLoading: true });

    const data = {
      Source: [
        ...source.value,
        ...(source.showOther ? [source.other] : []),
      ].join(', '),
      IncomeFromValue: wealth.incomeFrom,
      IncomeToValue: wealth.incomeTo,
      Industry: occupation.showOther ? occupation.other : occupation.industry,
      Occupation: occupation.showLevel ? occupation.level : '',
      Type: 'AnnualIncome',
    };

    sourceOfWealth(sessionId, data)
      .then(() => {
        this.nextStep();
        this.setState({ isLoading: false, enablePopupClose: true });
      })
      .catch(() => {
        this.setState({ error: true, isLoading: false });
      });
  };

  handleExpectedAnnualActivitySubmit = () => {
    const { expectedAnnualActivity } = this.state;
    const { sessionId, sourceOfWealth } = this.props;

    this.setState({ isLoading: true });

    const data = {
      IncomeFromValue: expectedAnnualActivity.activityFrom,
      IncomeToValue: expectedAnnualActivity.activityTo,
      Type: 'ExpectedAnnualActivity',
    };

    sourceOfWealth(sessionId, data)
      .then(() => {
        this.nextStep();
        this.setState({ isLoading: false, enablePopupClose: true });
      })
      .catch(() => {
        this.setState({ error: true, isLoading: false });
      });
  };

  showError = () => {
    const alertIds = createAlertId(null, 'danger');

    if (alertIds)
      return <Alert icon="exclamation-triangle" id={alertIds} level="danger" />;
  };

  intro = intro => {
    return (
      <div className="intro">
        <div
          dangerouslySetInnerHTML={{
            __html: marked(intro.content || ''),
          }}
        />

        <Button className="button primary wide" onClick={() => this.nextStep()}>
          {intro.cta}
        </Button>
      </div>
    );
  };

  source = question => {
    const { source } = this.state;
    const { intl, currency } = this.props;
    const alternatives = question.config?.alternatives;

    const { value, other, showOther } = source;

    return (
      <div className="source">
        <div
          dangerouslySetInnerHTML={{
            __html: marked(question.content || ''),
          }}
        />

        <div className="alternatives">
          {alternatives?.map((alternative, i) => (
            <Input
              type="checkbox"
              name={alternative?.value}
              label={translate(
                {
                  id: `${alternative?.id}`,
                },
                intl
              )}
              key={i}
              onChange={this.selectSource}
              value={value.includes(alternative?.value)}
            />
          ))}

          <Input
            type="checkbox"
            name="other"
            label={translate(
              {
                id: 'label.other',
                defaultMessage: 'Other',
              },
              intl
            )}
            onChange={this.selectSource}
            value={showOther}
          />

          {showOther && (
            <div className="other">
              <Textarea
                maxLength={140}
                onChange={e =>
                  this.setState({
                    source: {
                      ...source,
                      other: e.target.value,
                    },
                  })
                }
                value={other}
                placeholder={translate(
                  {
                    id: 'source-of-wealth.other-placeholder',
                    defaultMessage: 'Describe your source of wealth.',
                  },
                  intl
                )}
              />

              <p className="character-count">{other.length}/140</p>
            </div>
          )}
        </div>

        <Button
          disabled={
            (!value.length && !showOther) || (showOther && !other.trim().length)
          }
          className="button primary wide"
          onClick={this.nextStep}>
          {question.cta || 'Continue'}
        </Button>
      </div>
    );
  };

  occupation = question => {
    const { occupation } = this.state;
    const { intl, currency } = this.props;
    const alternatives = question.config?.alternatives;

    const { industry, level, other, showOther, showLevel } = occupation;

    return (
      <div className="occupation">
        <div
          dangerouslySetInnerHTML={{
            __html: marked(question.content || ''),
          }}
        />
        <div className="alternatives">
          <Select
            onChange={e =>
              this.setState({
                occupation: {
                  ...occupation,
                  showOther: e.target.value === 'other' ? !showOther : false,
                  showLevel: e.target.value === 'unemployed' ? false : true,
                  industry: e.target.value,
                },
              })
            }
            value={industry}
            label={translate(
              {
                id: 'source-of-wealth.industry',
                defaultMessage: 'The industry I work in is:',
              },
              intl
            )}>
            <Select.Option value="" selected disabled>
              {translate(
                {
                  id: 'source-of-wealth.industry',
                  defaultMessage: 'The industry I work in is:',
                },
                intl
              )}
            </Select.Option>

            {alternatives?.industries?.map((alternative, i) => (
              <Select.Option key={i} value={alternative?.value}>
                {translate(
                  {
                    id: alternative?.id,
                    defaultMessage: alternative?.value,
                  },
                  intl
                )}
              </Select.Option>
            ))}

            <Select.Option value="unemployed">
              {translate(
                {
                  id: `label.unemployed`,
                  defaultMessage: 'Unemployed',
                },
                intl
              )}
            </Select.Option>

            <Select.Option value="other">
              {translate(
                {
                  id: `label.other`,
                  defaultMessage: 'Other',
                },
                intl
              )}
            </Select.Option>
          </Select>

          {showOther && (
            <div className="other">
              <Input
                onChange={e =>
                  this.setState({
                    occupation: {
                      ...occupation,
                      other: e.target.value,
                    },
                  })
                }
                value={other}
                placeholder={translate(
                  {
                    id: 'source-of-wealth.occupation-other',
                    defaultMessage: 'Enter you industry.',
                  },
                  intl
                )}
              />
            </div>
          )}
        </div>

        {showLevel && (
          <div className="alternatives">
            <Input
              onChange={e =>
                this.setState({
                  occupation: {
                    ...occupation,
                    level: e.target.value,
                  },
                })
              }
              value={level}
              label={translate(
                {
                  id: 'source-of-wealth.employment-level',
                  defaultMessage: 'My occupation/work title is:',
                },
                intl
              )}
            />
          </div>
        )}

        <div className="button-wrapper">
          <Button className="button tertiary" onClick={this.previousStep}>
            <Translate
              id="source-of-wealth.previous"
              defaultMessage="Previous"
            />
          </Button>

          <Button
            className="button primary wide"
            onClick={this.nextStep}
            disabled={
              !industry ||
              (!level && showLevel) ||
              (showOther && !other.trim().length)
            }>
            {question.cta || 'Continue'}
          </Button>
        </div>
      </div>
    );
  };

  wealth = question => {
    const { wealth, isLoading, error } = this.state;
    const { intl, currency } = this.props;
    const alternatives = question.config?.alternatives[currency] || [];

    const { income, hasConfirmed } = wealth;

    return (
      <div className="wealth">
        {error && this.showError()}

        <div
          dangerouslySetInnerHTML={{
            __html: marked(question.content || ''),
          }}
        />

        <div className="alternatives">
          <Radio
            value={income}
            onChange={e => this.selectIncome(e, alternatives)}
            range={alternatives?.map(a => a.value)}
          />
          <hr />
          <Input
            type="checkbox"
            name="source-of-wealth.confirm"
            label={translate(
              {
                id: 'source-of-wealth.confirm-label',
                defaultMessage:
                  'I declare that my answers are true, accurate and complete.',
              },
              intl
            )}
            onChange={() =>
              this.setState({
                wealth: {
                  ...wealth,
                  hasConfirmed: !hasConfirmed,
                },
              })
            }
            value={hasConfirmed}
          />
        </div>

        <div className="button-wrapper">
          <Button className="button tertiary" onClick={this.previousStep}>
            <Translate
              id="source-of-wealth.previous"
              defaultMessage="Previous"
            />
          </Button>

          <Button
            isLoading={isLoading}
            className="button primary"
            onClick={this.handleSubmit}
            disabled={!income || !hasConfirmed || isLoading}>
            <Translate id="source-of-wealth.next" defaultMessage="Next" />
          </Button>
        </div>
      </div>
    );
  };

  expectedAnnualActivity = question => {
    const { expectedAnnualActivity, isLoading, error } = this.state;
    const { intl, currency, jurisdiction } = this.props;
    const alternatives = question.config?.alternatives[currency] || [];

    const { activity } = expectedAnnualActivity;

    if (jurisdiction !== 'mga') return this.nextStep();

    return (
      <div className="expectedAnnualIncome">
        {error && this.showError()}

        <div
          dangerouslySetInnerHTML={{
            __html: marked(question.content || ''),
          }}
        />

        <div className="alternatives">
          <Radio
            value={activity}
            onChange={e => this.selectExpectedAnnualActivity(e, alternatives)}
            range={alternatives?.map(a => a.value)}
          />
          <hr />
        </div>

        <div className="button-wrapper">
          <Button className="button tertiary" onClick={this.previousStep}>
            <Translate
              id="source-of-wealth.previous"
              defaultMessage="Previous"
            />
          </Button>

          <Button
            isLoading={isLoading}
            className="button primary"
            onClick={this.handleExpectedAnnualActivitySubmit}
            disabled={!activity || isLoading}>
            <Translate id="source-of-wealth.next" defaultMessage="Next" />
          </Button>
        </div>
      </div>
    );
  };

  completed = completed => {
    return (
      <div className="completed">
        <div
          dangerouslySetInnerHTML={{
            __html: marked(completed.content || ''),
          }}
        />

        <Button
          className="button primary wide"
          onClick={() => (
            (window.location.hash = ''),
            !window.location.pathname.match(/\/(casino)/)
              ? (window.location.pathname = '/casino')
              : ''
          )}>
          {completed.cta || 'Back to lobby'}
        </Button>
      </div>
    );
  };

  render() {
    const { isLoading, step, enablePopupClose } = this.state;
    const { sections, sets, isRouteDataLoading } = this.props;

    const intro = sections?.find(
      i => i.identifier === 'source-of-wealth-intro'
    );

    const completed = sections?.find(
      i => i.identifier === 'source-of-wealth-completed'
    );

    const questions = sets?.find(
      i => i.identifier === 'source-of-wealth-questions'
    );

    return (
      <Popup
        title={
          <Translate
            id="source-of-wealth.header"
            defaultMessage="Source of income"
            tagName="h1"
          />
        }
        preventClose={!enablePopupClose}
        showLoading={isLoading || isRouteDataLoading}>
        <div className="SourceOfWealthContainer">
          {step > 0 && step < 4 && (
            <p>
              <Translate
                id="source-of-wealth.question"
                defaultMessage="Question"
              />
              : {step}/3
            </p>
          )}

          {step === 0 && intro && this.intro(intro)}
          {step === 1 && questions && this.source(questions?.items?.[0])}
          {step === 2 && questions && this.occupation(questions?.items?.[1])}
          {step === 3 && questions && this.wealth(questions?.items?.[2])}
          {step === 4 &&
            questions &&
            this.expectedAnnualActivity(questions?.items?.[3])}
          {step === 5 && completed && this.completed(completed)}
        </div>
      </Popup>
    );
  }
}

export default SourceOfWealthContainer;
